<!-- 审核管理页 -->
<template>
  <div class="">
    <router-view style="height: 100%" />
  </div>
</template>
  
  <script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
  <style lang="scss" scoped>
</style>